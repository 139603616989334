import React, {Component} from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import moment from "moment";
import 'moment/locale/nl';

import './css/main.scss';

import config from './config/config';
import Language from './language/Language';
import Routes from './components/Routes';
import Navbar from './components/global/Navbar';
import Footer from "./components/global/Footer";
import Auth from "./components/auth/Auth";
import LoginForm from "./components/auth/LoginForm";

moment().locale('nl');

export default class App extends Component {
    constructor(props) {
        super(props);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);

        this.state = {
            activeLanguage: Language.getLanguage(),
            isLoggedIn: Auth.isUserLoggedIn(),
        };

        document.title = config.metaTitle;
    }

    login() {
        this.setState({isLoggedIn: Auth.isUserLoggedIn()});
    }

    logout() {
        Auth.logOut();
        this.setState({isLoggedIn: false,});
    }

    render() {
        return (
            <Router>
                <Navbar logout={this.logout} />
                <div className="bg-white">
                    {this.state.isLoggedIn ? <Routes /> : <LoginForm login={this.login} />}
                </div>
                <Footer />
            </Router>
        );
    }
}