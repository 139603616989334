import React, {Component} from 'react';
import PropTypes from 'prop-types';
import md5 from "md5";
import Auth from "./Auth";

export default class LoginForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loginForm: {
                username: '',
                password: '',
            },
            loginFormValid: {
                username: false,
                password: false,
            },
        };
    }

    handleFormChange = (e) => {
        const loginForm = this.state.loginForm;
        loginForm[e.target.name] = e.target.value;
        this.setState({
            loginForm,
            loginFormValid: this.validateForm(),
        });
    }

    handleFormSubmit = (e) => {
        e.preventDefault();
        if (this.state.loginFormValid.username && this.state.loginFormValid.password) {
            const data = {
                username: this.state.loginForm.username,
                password: md5(this.state.loginForm.password),
            };
            if (Auth.authenticate(data)) {
                this.props.login();
            } else {
                this.setState({
                    loginFormValid: {
                        username: false,
                        password: false,
                    },
                });
            }
        }
    }

    validateForm = () => {
        const loginForm = this.state.loginForm;
        return {
            username: loginForm.username !== '',
            password: loginForm.password !== '',
        };
    }

    render() {
        const {loginForm} = this.state;
        return (
            <div className="container-fluid py-5">
                <div className="row justify-content-center">
                    <div className="col-12 col-md-6 col-lg-3">
                        <div className="card card-body">
                            <h1>Inloggen</h1>
                            <form onSubmit={this.handleFormSubmit} noValidate>
                                <div className="form-group">
                                    <label htmlFor="username">Login</label>
                                    <input type="text" className={'form-control' + (!this.state.loginFormValid.username ? ' is-invalid' : '')} id="username" name="username" value={loginForm.username} onChange={this.handleFormChange} autoFocus required />
                                </div>
                                <div className="form-group">
                                    <label htmlFor="password">Wachtwoord</label>
                                    <input type="password" className={'form-control' + (!this.state.loginFormValid.password ? ' is-invalid' : '')} id="password" name="password" value={loginForm.password} onChange={this.handleFormChange} required />
                                </div>
                                <button type="submit" className="btn btn-primary">Go!</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

LoginForm.propTypes = {
    login: PropTypes.func.isRequired,
};