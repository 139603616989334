import React from 'react';
import PropTypes from 'prop-types';
import {Route, NavLink, Link} from 'react-router-dom';
import {Navbar as BsNavbar, Nav} from 'react-bootstrap';

import Language from "../../language/Language";
import Auth from "../auth/Auth";
import logo from "../../logo-tappan-zwart.svg";

const Navbar = props => {
    return (
        <BsNavbar bg="beige" variant="light" expand="lg">
            <Link className="navbar-brand mr-auto" to="/"><img className="logo" src={logo} alt={Language.getTranslation('brand')} /></Link>
            {Auth.isUserLoggedIn() && <Nav>
                {Auth.isUserAdmin() && <span className="navbar-text mr-md-3">Staf</span>}
                <li className="nav-item">
                    <button className="btn btn-light" onClick={props.logout}>Uitloggen</button>
                </li>
            </Nav>}
        </BsNavbar>
    );
}

const NavItem = props => {
    const {exact, label, to} = props;
    return (
        <Route path={to} exact={exact}>
            {({ match }) => <li className={'nav-item ' + (match ? 'active' : '')}>
                <NavLink to={to} className="nav-link">
                    {label}
                </NavLink>
            </li>}
        </Route>
    );
};

export default Navbar;

Navbar.propTypes = {
    logout: PropTypes.func.isRequired,
};
NavItem.propTypes = {
    exact: PropTypes.any,
    label: PropTypes.string.isRequired,
    to: PropTypes.string.isRequired,
};
