import React, {Fragment} from 'react';
import { Route } from 'react-router-dom';

import Index from "./workplace/Index";

const Routes = () => {
    return (
        <Fragment>
            <Route path="/" exact strict component={Index} />
        </Fragment>
    );
};

export default Routes;