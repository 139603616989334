import ApiConnector from "./ApiConnector";

const Api = {
    addBookings(data) {
        return new Promise((resolve, reject) => {
            ApiConnector.connect('post', 'bookings', true, data)
                .then(data => resolve(data))
                .catch(error => reject(error));
        });
    },
    editBookings(data) {
        return new Promise((resolve, reject) => {
            ApiConnector.connect('post', 'bookings/update', true, data)
                .then(data => resolve(data))
                .catch(error => reject(error));
        });
    },
    addEditComment(data) {
        return new Promise((resolve, reject) => {
            ApiConnector.connect('post', 'date-comment', true, data)
                .then(data => resolve(data))
                .catch(error => reject(error));
        });
    },
    getWeek(weekNr, yearNr) {
        return new Promise((resolve, reject) => {
            if (weekNr === undefined) {
                reject('weekNr undefined');
            }
            if (yearNr === undefined) {
                reject('yearNr undefined');
            }

            ApiConnector.connect('get', 'availability/' + weekNr + '/' + yearNr, true)
                .then(data => resolve(data))
                .catch(error => reject(error));
        });
    },
};

export default Api;