const Auth = {
    authenticate(loginForm) {
        if (loginForm.username === 'tappan' && loginForm.password === '828f2893c3b077f93a859fa5fa74c2d7') {
            saveAuthToken('USER');
            return true;
        } else if (loginForm.username === 'admin' && loginForm.password === '55bfeb6a5a670b09152e22eeb05c40c4') {
            saveAuthToken('ADMIN');
            return true;
        }
        return false;
    },
    isUserLoggedIn() {
        return sessionStorage.getItem('isLoggedIn') === 'true';
    },
    isUserAdmin() {
        return sessionStorage.getItem('userRole') === 'ADMIN';
    },
    logOut() {
        sessionStorage.removeItem('isLoggedIn');
        sessionStorage.removeItem('userRole');
    },
};

function saveAuthToken(role) {
    sessionStorage.setItem('isLoggedIn', 'true');
    sessionStorage.setItem('userRole', role);
}

export default Auth;