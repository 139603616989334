import moment from "moment";

const HelperFunctions = {
    getItemFromArrayByValue(arr, attr, value) {
        for (let i = 0, iLen = arr.length; i < iLen; i++) {
            if (arr[i][attr] === value) return arr[i];
        }
    },
    getParameterFromUrl(param) {
        let search = '/' + param + '/';
        let posStart = window.location.pathname.indexOf(search) + search.length; // locate the parameter in the url
        let posEnd = window.location.pathname.indexOf('/', posStart);
        if (posEnd === -1) {
            return window.location.pathname.slice(posStart);
        } else {
            return window.location.pathname.slice(posStart, posEnd);
        }
    },
    getWeek(weekNr, yearNr) {
        if (weekNr === undefined) {
            return false;
        }
        if (yearNr === undefined) {
            return false;
        }

        const monday = moment().isoWeek(weekNr).isoWeekYear(yearNr).startOf('isoWeek');
        const friday = moment().isoWeek(weekNr).isoWeekYear(yearNr).startOf('isoWeek').add(4, 'days');
        const mondayDate = monday.format((monday.month() !== friday.month()) ? 'D MMMM' : 'D');
        const fridayDate = friday.format('D MMMM');

        return mondayDate + ' t/m ' + fridayDate;
    },
    handleKeyPress(event, key, callback) {
        if (event.key === key) {
            callback();
        }
    },
    handleKeyPressRef(event, key, ref) {
        if (event.key === key) {
            ref.click();
        }
    },
    hasNumber(myString) {
        return /\d/.test(myString);
    },
    hasAlphabetical(myString) {
        return /[a-z]/i.test(myString);
    },
    isInt(value) {
        let x;
        return isNaN(value) ? !1 : (x = parseFloat(value), (0 | x) === x);
    },
    onlyAllowAlphaNumeric(str) {
        return str.replace(/[^a-z0-9À-ÖØ-öø-ÿ -]/gi,'');
    },
};

export default HelperFunctions;
