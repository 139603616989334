import axios from "axios/index";
import config from "../../config/config";
import constants from "../../config/constants";
import env from "../../config/env";

const ApiConnector = {
    connect(method, apiUrlPath, authenticate, data) {
        return new Promise((resolve, reject) => {
            if (authenticate === undefined) {
                authenticate = false;
            }
            const baseUrl = getApiBaseUrl();
            if (baseUrl) {
                const apiUrl = baseUrl + apiUrlPath;
                const options = getApiHeaders(authenticate, method === 'upload');
                if (env.environment === constants.environment.development) {
                    // console.log('DEV | api ' + method, apiUrl, options);
                }
                const params = {
                    apiUrl,
                    data,
                    options,
                };
                const apiMethod = getApiMethod(method, params);

                apiMethod()
                    .then(result => {
                        if (result.status >= 200 && result.status < 300) {
                            resolve(result.data);
                        } else {
                            let error = new Error(result);
                            error.result = result;
                            throw error;
                        }
                    })
                    .catch(error => {
                        // Api returned an error
                        const errorCode = handleApiErrors(error);
                        const context = {errorCode: errorCode, data: error};
                        reject(context);
                    });
            } else {
                reject('No api base url');
            }
        });
    },
    upload(apiUrlPath, authenticate, file) {
        return new Promise((resolve, reject) => {
            if (file === undefined) {
                reject('no file received for upload');
            }

            const data = new FormData();
            data.append('file', file);

            this.connect('upload', apiUrlPath, authenticate, data)
                .then(result => resolve(result))
                .catch(error => reject(error));
        });
    },
};

function getApiBaseUrl() {
    if (env.apiBaseUrl === undefined) {
        return false;
    } else {
        return env.apiBaseUrl;
    }
}

function getApiHeaders(authenticate, upload) {
    const authToken = config.apiAuthToken;
    const options = {headers: {}};
    if (authenticate) {
        switch (config.apiAuthMethod) {
            case 'authToken':
            default:
                options.headers.Authorization = 'Bearer '.concat(authToken);
                break;
            case 'apiKey':
                options.headers["X-AUTH-TOKEN"] = authToken;
                break;
        }
    }
    if (upload) {
        options.headers['Content-Type'] = 'multipart/form-data';
    }
    // env.environment === constants.environment.development && console.log('DEV | api headers', options.headers);
    return options;
}

function getApiMethod(method, params) {
    let axiosMethod;
    switch (method) {
        case 'delete':
            axiosMethod = () => axios.delete(params.apiUrl, params.options);
            break;
        case 'get':
        default:
            axiosMethod = () => axios.get(params.apiUrl, params.options);
            break;
        case 'patch':
            axiosMethod = () => axios.patch(params.apiUrl, params.data, params.options);
            break;
        case 'post':
            axiosMethod = () => axios.post(params.apiUrl, params.data, params.options);
            break;
        case 'put':
            axiosMethod = () => axios.put(params.apiUrl, params.data, params.options);
            break;
        case 'upload':
            axiosMethod = () => axios.post(params.apiUrl, params.data, params.options);
            break;
    }
    return axiosMethod;
}

function handleApiErrors(error, comment) {
    let errorCode;

    if (comment === undefined) {
        comment = '';
    }

    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        // env.environment === constants.environment.development && console.log('data:', error.response.data);
        // env.environment === constants.environment.development && console.log('status:', error.response.status);
        // env.environment === constants.environment.development && console.log('headers:', error.response.headers);

        notifyOnError(error.response.status, false, comment);
        errorCode = error.response.status;
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        // env.environment === constants.environment.development && console.log('request:', error.request);

        errorCode = 1000;
        notifyOnError(1000, true, error.request);
    } else {
        // Something happened in setting up the request that triggered an Error
        // env.environment === constants.environment.development && console.log('Error message:', error.message);

        errorCode = 1001;
        notifyOnError(1001, true, error.message);
    }

    return errorCode;
}

function notifyOnError(statusCode, showUserMessage, additionalInfo) {
    let userMessage;
    let consoleMessage;
    switch (statusCode) {
        case '400':
            userMessage = '';
            consoleMessage = 'Data not valid';
            break;
        case '401':
            userMessage = '';
            consoleMessage = 'Invalid credentials';
            break;
        case '403':
            userMessage = '';
            consoleMessage = 'Access denied';
            break;
        case '1000':
            userMessage = 'applicationUnavailable';
            consoleMessage = 'API not reachable';
            break;
        case '1001':
            userMessage = 'applicationUnavailable';
            consoleMessage = 'API request error';
            break;
        default:
            userMessage = '';
            consoleMessage = 'Unknown API error';
            break;
    }

    if (showUserMessage) {
        console.log(userMessage);
    }

    if (env.environment === 'development') {
        console.log('DEV | ' + consoleMessage);
        if (additionalInfo) {
            console.log('DEV | additional info: ', additionalInfo);
        }
    }
}

export default ApiConnector;