import React, { Component } from "react";
import PropTypes from "prop-types";
import Parser from "html-react-parser";
import moment from "moment";
import Api from "../api/Api";
import HelperFunctions from "../global/HelperFunctions";
import LoadingSpinner from "../global/LoadingSpinner";
import env from "../../config/env.json";
import constants from "../../config/constants.json";
import Auth from "../auth/Auth";
// import Wysiwyg from "../global/Wysiwyg";
import { Alert } from "react-bootstrap";
import { add, getISOWeek, getISOWeekYear, parse, sub } from "date-fns";

export default class Index extends Component {
  constructor(props) {
    super(props);

    this.currentDay = moment();
    // if (env.apiBaseUrl) {
    //     this.currentDay = moment('2021-01-01');
    // }
    this.currentWeekNr = this.currentDay.isoWeek();
    this.currentYearNr = this.currentDay.year();
    this.firstAvailableWeek = this.currentWeekNr;
    this.firstAvailableYear = this.currentYearNr;
    this.refreshCode = Math.random();

    this.state = {
      apiTestResult: 0,
      data: [],
      loadingData: true,
      showSubmitFailed: false,
      showSubmitSuccess: false,
      week: HelperFunctions.getWeek(this.currentWeekNr, this.currentYearNr),
      weekNr: this.currentWeekNr,
      yearNr: this.currentYearNr,
    };
  }

  changeWeek = (weekNr, yearNr, browse) => {
    const givenWeek = parse(`${weekNr} ${yearNr}`, "I R", new Date());
    const prevWeek = sub(givenWeek, { weeks: 1 });
    const nextWeek = add(givenWeek, { weeks: 1 });

    if (browse === "next") {
      weekNr = getISOWeek(nextWeek);
      yearNr = getISOWeekYear(nextWeek);
    }
    if (browse === "prev") {
      weekNr = getISOWeek(prevWeek);
      yearNr = getISOWeekYear(prevWeek);
    }

    this.setState({ ...this.state, loadingData: true });
    Api.getWeek(weekNr, yearNr)
      .then((data) => {
        this.setState({
          ...this.state,
          data,
          loadingData: false,
          showSubmitSuccess: false,
          week: HelperFunctions.getWeek(weekNr, yearNr),
          weekNr: weekNr,
          yearNr: yearNr,
        });
      })
      .catch((error) => {
        env.environment === constants.environment.development &&
          console.log(
            "DEV | data NIET opgehaald, week " + weekNr + ", year" + yearNr,
            error
          );
        this.setState({
          ...this.state,
          data: [],
          loadingData: false,
          showSubmitSuccess: false,
          week: HelperFunctions.getWeek(weekNr),
          weekNr: weekNr,
        });
      });
  };

  componentDidMount() {
    this.changeWeek(this.state.weekNr, this.state.yearNr, null);
  }

  handleFormSubmit = (e, formData, date) => {
    e.preventDefault();
    this.setState({ ...this.state, loadingData: true });

    let promises = [];
    if (formData.add.length > 0) {
      promises.push(Api.addBookings(formData.add));
    }
    if (Auth.isUserAdmin() && formData.edit.length > 0) {
      promises.push(Api.editBookings(formData.edit));
    }
    if (Auth.isUserAdmin()) {
      const commentData = {
        comment: formData.comment,
        date,
      };
      promises.push(Api.addEditComment(commentData));
    }
    if (promises.length > 0) {
      Promise.all(promises)
        .then(() => {
          this.changeWeek(this.state.weekNr, this.state.yearNr, null);
        })
        .catch(() => {
          this.changeWeek(this.state.weekNr, this.state.yearNr, null);
        });
    } else {
      this.setState({ ...this.state, loadingData: false });
    }
  };

  render() {
    let prevWeekDisabled =
      Auth.isUserAdmin() ||
      this.state.loadingData ||
      (this.state.weekNr <= this.firstAvailableWeek &&
        this.state.yearNr >= this.firstAvailableYear);

    if (Auth.isUserAdmin()) {
      prevWeekDisabled = false;
    }

    const prevWeekClass = prevWeekDisabled ? "btn-light" : "btn-primary";

    return (
      <div className="container-fluid position-relative">
        <div className="px-md-5">
          <div className="row pt-4 pb-3">
            <div className="col-12 col-md-6 col-lg-5">
              <div className="row">
                <div className="col-auto">
                  <button
                    type="button"
                    className={"btn " + prevWeekClass}
                    disabled={prevWeekDisabled}
                    onClick={() =>
                      this.changeWeek(
                        this.state.weekNr,
                        this.state.yearNr,
                        "prev"
                      )
                    }
                  >
                    <span className="fa fa-chevron-left" />
                  </button>
                </div>
                <div className="col text-center text-nowrap">
                  Week {this.state.weekNr}|{this.state.yearNr}
                  <br />
                  <small className="text-muted text-uppercase">
                    {this.state.week}
                  </small>
                </div>
                <div className="col-auto">
                  <button
                    type="button"
                    className={"btn ml-1 btn-primary"}
                    onClick={() =>
                      this.changeWeek(
                        this.state.weekNr,
                        this.state.yearNr,
                        "next"
                      )
                    }
                  >
                    <span className="fa fa-chevron-right" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className="py-4">
            {this.state.loadingData ? (
              <LoadingSpinner />
            ) : this.state.data.length > 0 ? (
              <div className={"card-deck"}>
                {this.state.data.map((day, index) => (
                  <Day
                    comment={day.comment}
                    day={day}
                    dayNr={index}
                    handleFormSubmit={this.handleFormSubmit}
                    weekNr={this.state.weekNr}
                    yearNr={this.state.yearNr}
                    workplaces={day.workplaces}
                    key={this.state.weekNr + "day" + index + this.refreshCode}
                  />
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

class Day extends Component {
  constructor(props) {
    super(props);

    const comment = this.props.comment != null ? this.props.comment : "";
    this.state = {
      formData: {
        add: [],
        edit: [],
        comment,
      },
      formHasChanged: false,
    };
  }

  handleCommentChange = (e) => {
    const formData = this.state.formData;
    formData.comment = e;
    this.setState({
      formData,
      formHasChanged: true,
    });
  };

  handleFormChange = (workplace, value) => {
    if (workplace == null || workplace.id == null || value == null) {
      return;
    }
    const formData = this.state.formData;
    let list = "add";
    let item = {
      date: this.props.day.date,
      employee: value,
      workplace: workplace.id,
    };

    if (!workplace.available) {
      list = "edit";
      item.id = workplace.booking.id;
      item.delete = value === "";
    }

    if (formData[list].length > 0) {
      let itemFound = false;
      formData[list].forEach((listItem, listIndex) => {
        if (listItem.workplace === workplace.id) {
          formData[list][listIndex] = item;
          itemFound = true;
        }
      });
      !itemFound && formData[list].push(item);
    } else {
      formData[list].push(item);
    }

    this.setState({
      formData,
      formHasChanged: true,
    });
  };

  stripHTML = (html) => {
    let doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  render() {
    const { comment, day, dayNr, handleFormSubmit, weekNr, workplaces } =
      this.props;
    if (dayNr > 4 || workplaces === undefined || workplaces.length < 1) {
      return null;
    }

    const btnClass = this.state.formHasChanged ? "btn-primary" : "btn-light";

    return (
      <form
        className="card card-werkplek"
        onSubmit={(e) =>
          handleFormSubmit(e, this.state.formData, day.dateFormatted)
        }
      >
        <div className="card-header text-center">
          <h1 className="h5">
            {moment(day.dateFormatted).format("dddd")}{" "}
            <span className="d-block text-nowrap">
              {moment(day.dateFormatted).format("D MMMM")}
            </span>
          </h1>
          <p className="text-center">
            <button
              type="submit"
              disabled={!this.state.formHasChanged}
              className={"btn btn-sm " + btnClass}
            >
              Opslaan
            </button>
          </p>
        </div>
        <div className="card-body">
          {Auth.isUserAdmin() ? (
            <div className="form-group">
              <textarea
                value={this.stripHTML(this.state.formData.comment)}
                onChange={(e) => this.handleCommentChange(e.target.value)}
                rows={5}
                style={{ width: "100%" }}
              />
              {/* Disabled for now, TinyMCE requires a license now */}
              {/* <Wysiwyg
                wysiwygConfig="small"
                value={this.state.formData.comment}
                handleChange={this.handleCommentChange}
              /> */}
            </div>
          ) : comment != null ? (
            <Alert variant="info">{Parser(comment)}</Alert>
          ) : null}
        </div>
        <div className="card-footer">
          {workplaces.map((workplace) => (
            <EmployeeField
              handleFormChange={this.handleFormChange}
              workplace={workplace}
              key={weekNr + workplace.id}
            />
          ))}
          <p>
            <button
              type="submit"
              disabled={!this.state.formHasChanged}
              className={"btn btn-sm " + btnClass}
            >
              Opslaan
            </button>
          </p>
        </div>
      </form>
    );
  }
}

export class EmployeeField extends Component {
  constructor(props) {
    super(props);

    let employee = null;
    if (
      this.props.workplace.booking != null &&
      this.props.workplace.booking.hasOwnProperty("employee")
    ) {
      employee =
        this.props.workplace.booking.employee != null
          ? this.props.workplace.booking.employee
          : null;
    }

    this.state = {
      blocked: employee === "Geblokkeerd",
      employee: employee != null ? employee : "",
    };
  }

  handleChange = (e) => {
    const employee = HelperFunctions.onlyAllowAlphaNumeric(e.target.value);
    this.setState({ employee });
    this.props.handleFormChange(this.props.workplace, employee);
  };

  render() {
    let disabled =
      !Auth.isUserAdmin() &&
      (!this.props.workplace.available || this.state.blocked);
    return (
      <div className="form-group row">
        <label
          htmlFor="employee"
          className="col-1 col-form-label col-form-label-sm text-nowrap"
        >
          {this.props.workplace.id}.
        </label>
        <div className="col">
          <input
            type="text"
            className="form-control form-control-sm"
            disabled={disabled}
            id="employee"
            placeholder="Naam"
            value={this.state.employee}
            onChange={this.handleChange}
            maxLength={50}
          />
        </div>
      </div>
    );
  }
}

Day.propTypes = {
  comment: PropTypes.string,
  day: PropTypes.object.isRequired,
  dayNr: PropTypes.number.isRequired,
  handleFormSubmit: PropTypes.func.isRequired,
  weekNr: PropTypes.number.isRequired,
  workplaces: PropTypes.array.isRequired,
};
EmployeeField.propTypes = {
  handleFormChange: PropTypes.func.isRequired,
  workplace: PropTypes.object.isRequired,
};
