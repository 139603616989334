import React from 'react';
import Language from "../../language/Language";
import footer from "../../tappan-coronavrij.svg";

const Footer = () => {
    return (
        <div className="py-4 bg-light text-center">
            <img className="footer-img" src={footer} alt={Language.getTranslation('footer')} />
        </div>
    );
};

export default Footer;